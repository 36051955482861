.app-bar {
  background: #ffffff !important;
  box-shadow: 0px 1px 0px rgba(219, 223, 229, 0.6) !important;
}

.logo {
  width: 130px;
  height: 25px;
  margin: 16px;
}
